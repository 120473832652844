<template>
    <div class="chart-view">
        <div class="l-padded chart-view__datepicker">
            <DateRangeInput v-model="customRange" />
        </div>

        <div class="l-stack l-gap-2 chart-view__chart">
            <AssetChartsSettings @change="handleSettingsChange" />

            <div v-if="isLoading" class="l-stack l-center l-padded">
                <VSpinner size="medium" line-fg-color="#000" :speed="1" />
            </div>

            <template v-else>
                <AssetChartsStats
                    :colors="chartOptions.colors"
                    :series="series"
                    :units="[unit]"
                />

                <ApexChart
                    ref="chart"
                    height="360px"
                    :options="chartOptions"
                    :series="series"
                />
            </template>
        </div>
    </div>
</template>

<script>
import moment from 'moment-timezone'
import ApexChart from 'vue-apexcharts'
import VSpinner from 'vue-simple-spinner'

import { httpHelper, measurementHelper } from '@/utils'
import AssetChartsSettings from '@/components/AssetChartsSettings'
import AssetChartsStats from '@/components/AssetChartsStats'
import ChartHelper from '@/mixins/ChartHelper'
import CustomSocketHelper from '@/mixins/CustomSocketHelper'
import DateRangeInput from '@/components/DateRangeInput'
import UrlHelper from '@/mixins/UrlHelper'

export default {
    name: 'AssetChartsView',
    components: {
        ApexChart,
        AssetChartsSettings,
        AssetChartsStats,
        DateRangeInput,
        VSpinner,
    },
    mixins: [ChartHelper, CustomSocketHelper, UrlHelper],
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
        dataType: {
            type: String,
            required: true,
        },
        assetType: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            series: [
                {
                    data: [],
                },
            ],
            chartOptions: {
                chart: {
                    type: 'line',
                    stacked: false,
                    animations: {
                        enabled: false,
                    },
                    zoom: {
                        type: 'x',
                        enabled: true,
                        autoScaleYaxis: true,
                    },
                    toolbar: {
                        autoSelected: 'zoom',
                    },
                    ...(this.dataType === 'accel_y' && {
                        type: 'scatter',
                    }),
                },
                stroke: {
                    curve: 'straight',
                },
                dataLabels: {
                    enabled: false,
                },
                markers: {
                    size: 0,
                    style: 'full',
                    ...(this.dataType === 'accel_y' && {
                        size: 8,
                    }),
                },
                yaxis: {
                    title: {
                        text: this.getTranslatedWithPrefix(`yaxis_`),
                    },
                    min: this.getYMinValue(this.dataType),
                    max: this.getYMaxValue(this.dataType),
                    ...(['bin', 'sbb-bin'].includes(this.assetType) &&
                        this.dataType === 'distance' && {
                            title: {
                                text: this.$t('yaxis_fillLevel'),
                            },
                            min: 0,
                            max: 100,
                        }),
                    ...(this.dataType === 'accel_y' && {
                        show: false,
                        tickAmount: 2,
                        min: 0,
                        max: 2,
                    }),
                },
                xaxis: {
                    type: 'datetime',
                },
                annotations: {},
                tooltip: {
                    shared: false,
                    x: {
                        format: 'dd.MM.yy HH:mm:ss',
                    },
                    ...(this.dataType === 'accel_y' && {
                        custom: () => null,
                    }),
                },
            },
            customRange: {
                startDate: moment()
                    .startOf('day')
                    .toDate(),
                endDate: moment()
                    .endOf('day')
                    .toDate(),
            },
            isLoading: false,
        }
    },
    computed: {
        converter() {
            if (this.dataType === 'distance') {
                if (this.assetType === 'bin') {
                    return measurementHelper.convertToBinLevel
                } else if (this.assetType === 'sbb-bin') {
                    return measurementHelper.convertToSbbBinLevel
                }
            } else if (this.dataType === 'mass' && this.assetType === 'silo') {
                return measurementHelper.converters.silo_mass
            } else if (
                (this.assetType === 'fence-guard' &&
                    /^voltage_/.test(this.dataType)) ||
                (this.assetType === 'fency-boy' &&
                    /^voltage_/.test(this.dataType))
            ) {
                return measurementHelper.converters.fence_voltage
            }

            return (
                measurementHelper.converters[this.dataType] ||
                measurementHelper.converters.default
            )
        },
        unit() {
            return {
                ...measurementHelper.units,
                ...measurementHelper.unitsByAssetType[this.assetType],
            }[this.dataType]
        },
    },
    watch: {
        customRange() {
            this.load()
        },
        dataType() {
            this.chartOptions.yaxis.title.text = this.getTranslatedWithPrefix(
                'yaxis_'
            )
            this.chartOptions.yaxis.min = this.getYMinValue(this.dataType)
            this.chartOptions.yaxis.max = this.getYMaxValue(this.dataType)
            this.load()
        },
    },
    mounted() {
        this.keepAsQueryParams(true, {
            'customRange.startDate': {
                key: 'start',
                type: 'date',
            },
            'customRange.endDate': {
                key: 'end',
                type: 'date',
            },
        })
    },
    sockets: {
        sensor_data(data) {
            if (
                data.tracker == this.id &&
                Object.prototype.hasOwnProperty.call(data.values, this.dataType)
            ) {
                const time = new Date(data.timestamp)

                if (
                    time >= this.customRange.startDate &&
                    time <= this.customRange.endDate
                ) {
                    const newItem = [
                        data.timestamp,
                        this.converter(data.values[this.dataType]),
                    ]

                    this.series = [
                        {
                            name: this.series[0].name,
                            data: [newItem, ...this.series[0].data],
                        },
                    ]

                    if (this.lastZoom) {
                        this.$refs.chart.zoomX(...this.lastZoom)
                    }
                }
            }
        },
    },
    methods: {
        getAgrolaSiloAnnotations(data) {
            return data
                .reduce(
                    (acc, cur) => {
                        let range = acc[acc.length - 1]
                        if (cur.sensor_data.angle > 60) {
                            range[range.length ? 1 : 0] = new Date(
                                cur.timestamp
                            ).getTime()
                        } else if (range.length) {
                            acc.push([])
                        }
                        return acc
                    },
                    [[]]
                )
                .filter(range => range.length)
                .map(([x, x2]) => ({
                    x,
                    x2,
                    fillColor: '#fdae61',
                    label: {
                        offsetX: 9,
                        text: this.$t('moving'),
                    },
                }))
        },
        getTranslatedWithPrefix(prefix) {
            let assetTypePrefix = ''
            switch (this.assetType) {
                case 'dragino':
                case 'ecocoach':
                case 'fency-boy':
                case 'roadMaintenance':
                case 'silo':
                    assetTypePrefix = this.assetType + '_'
            }
            return this.$te(`${prefix}${assetTypePrefix}${this.dataType}`) ||
                this.$root.$te(`${prefix}${assetTypePrefix}${this.dataType}`)
                ? this.$t(`${prefix}${assetTypePrefix}${this.dataType}`)
                : this.$t(`${prefix}${this.dataType}`)
        },
        async load() {
            this.handleZoomReset()
            this.isLoading = true
            const results = await this.loadData()
            this.setData(results)
            this.isLoading = false
        },
        async loadData() {
            let results = []
            let url =
                'measurements/?' +
                `tracker=${this.id}` +
                '&fields=timestamp,sensor_data' +
                `&timestamp_min=${encodeURIComponent(
                    moment(this.customRange.startDate).format()
                )}` +
                `&timestamp_max=${encodeURIComponent(
                    moment(this.customRange.endDate).format()
                )}` +
                `&limit=${process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST}`

            while (url) {
                const { data } = await httpHelper.get(url)
                results = results.concat(data.results)
                url = data.next
            }

            return results
        },
        setData(results) {
            let name = this.getTranslatedWithPrefix('router.')
            let data = results.filter(item =>
                Object.prototype.hasOwnProperty.call(
                    item.sensor_data,
                    this.dataType
                )
            )

            if (this.dataType === 'distance') {
                if (this.assetType === 'bin') {
                    name = this.$t('yaxis_fillLevel')
                } else if (this.assetType === 'sbb-bin') {
                    name = this.$t('yaxis_fillLevel')
                }
            } else if (
                this.dataType === 'fill_level' &&
                this.assetType === 'agrola-silo'
            ) {
                this.chartOptions.annotations.xaxis = this.getAgrolaSiloAnnotations(
                    data
                )
            }

            if (this.dataType === 'humidity_absolute') {
                data = data.map(item => [
                    item.timestamp,
                    measurementHelper.calculateAbsoluteHumidity(
                        item.sensor_data.humidity,
                        item.sensor_data.temperature
                    ),
                ])
            } else {
                data = data.map(item => [
                    item.timestamp,
                    this.converter(item.sensor_data[this.dataType]),
                ])
            }

            this.series = [
                {
                    key: this.dataType,
                    name,
                    data,
                    unit: this.unit,
                },
            ]
        },
    },
}
</script>

<i18n>
{
    "en": {
        "moving": "Moving",
        "yaxis_barometric_pressure": "Barometric pressure (hPa)",
        "yaxis_battery": "Battery (%)",
        "yaxis_battery_percentage": "State of Charge (%)",
        "yaxis_battery_voltage": "Battery voltage (V)",
        "yaxis_brightness": "Brightness",
        "yaxis_co2": "Carbon dioxide (ppm)",
        "yaxis_d1_count": "Power up",
        "yaxis_distance": "Distance (m)",
        "yaxis_dragino_voltage_0": "Voltage 0 (V)",
        "yaxis_dragino_voltage_1": "Voltage 1 (V)",
        "yaxis_energy_0": "Energy (kWh)",
        "yaxis_ev_battery_health": "EV battery health (%)",
        "yaxis_ev_battery_percentage": "EV battery percentage (%)",
        "yaxis_ev_charging_state": "EV charging state",
        "yaxis_external_voltage": "External voltage (V)",
        "yaxis_fency-boy_d1_count": "Impulses",
        "yaxis_fency-boy_voltage_0": "Fence voltage (kV)",
        "yaxis_fillLevel": "Fill level (%)",
        "yaxis_fill_level": "Fill level (%)",
        "yaxis_fuel_level": "Fuel level (%)",
        "yaxis_fuel_rate": "Fuel rate (l/h)",
        "yaxis_humidity": "Humidity (%)",
        "yaxis_humidity_absolute": "Absolute humidity (g/m³)",
        "yaxis_level": "Level (m)",
        "yaxis_lightning_average_distance": "Lightning average distance (km)",
        "yaxis_lightning_strike_count": "Lightning strike count",
        "yaxis_lower_loop": "Lower loop",
        "yaxis_mass": "Mass (kg)",
        "yaxis_maximum_wind_speed": "Maximum wind speed (m/s)",
        "yaxis_odometer": "Kilometer (km)",
        "yaxis_potential": "Potential",
        "yaxis_power": "Total (W)",
        "yaxis_precipitation": "Precipitation (mm/h)",
        "yaxis_precipitation_absolute": "Precipitation absolute (mm)",
        "yaxis_precipitation_intensity": "Precipitation intensity (mm/h)",
        "yaxis_roadMaintenance_humidity": "Air humidity (%)",
        "yaxis_roadMaintenance_temperature_0": "Air temperature (°C)",
        "yaxis_roadMaintenance_temperature_1": "Surface temperature (°C)",
        "yaxis_roadMaintenance_temperature_2": "Sensor head temperature (°C)",
        "yaxis_roadMaintenance_temperature_3": "Dew point (°C)",
        "yaxis_running_time": "Running Time",
        "yaxis_signal": "RSSI (dBm)",
        "yaxis_silo_mass": "Mass (t)",
        "yaxis_solar_radiation": "Solar radiation (W/m^2)",
        "yaxis_speed": "Speed (km/h)",
        "yaxis_tachograph_odometer": "Kilometer (km)",
        "yaxis_tau": "Tau",
        "yaxis_temperature": "Temperature (°C)",
        "yaxis_temperature_0": "Battery Temperature (°C)",
        "yaxis_temperature_1": "AFE Temperature (°C)",
        "yaxis_upper_loop": "Upper loop",
        "yaxis_vapor_pressure": "Vapor pressure (kPa)",
        "yaxis_voc": "Volatile organic compounds (ppb)",
        "yaxis_voltage_0": "Battery Voltage (V)",
        "yaxis_volume": "Volume (m³)",
        "yaxis_water_level": "Water level",
        "yaxis_wind_direction": "Wind direction (°)",
        "yaxis_wind_speed": "Wind speed (m/s)"
    },
    "de": {
        "moving": "Umzug",
        "yaxis_barometric_pressure": "Barometrischer Luftdruck (hPa)",
        "yaxis_battery": "Batterie (%)",
        "yaxis_battery_percentage": "Ladezustand (%)",
        "yaxis_battery_voltage": "Batteriespannung (V)",
        "yaxis_brightness": "Helligkeit",
        "yaxis_co2": "Kohlenstoffdioxid (ppm)",
        "yaxis_d1_count": "Power up",
        "yaxis_distance": "Distanz (m)",
        "yaxis_dragino_voltage_0": "Spannung 0 (V)",
        "yaxis_dragino_voltage_1": "Spannung 1 (V)",
        "yaxis_energy_0": "Energie (kWh)",
        "yaxis_ev_battery_health": "EV Batterie Zustand (%)",
        "yaxis_ev_battery_percentage": "EV Batterieladung (%)",
        "yaxis_ev_charging_state": "EV Ladezustand",
        "yaxis_external_voltage": "Externe Spannung (V)",
        "yaxis_fency-boy_d1_count": "Impulse",
        "yaxis_fency-boy_voltage_0": "Spannung Weidezaun (kV)",
        "yaxis_fillLevel": "Füllstand (%)",
        "yaxis_fill_level": "Füllstand (%)",
        "yaxis_fuel_level": "Kraftstoffstand (%)",
        "yaxis_fuel_rate": "Kraftstoffmenge (l/h)",
        "yaxis_humidity": "Luftfeuchtigkeit (%)",
        "yaxis_humidity_absolute": "Absolute Luftfeuchtigkeit (g/m³)",
        "yaxis_level": "Pegel (m)",
        "yaxis_lightning_average_distance": "Durchschnittliche Entfernung der Blitze (km)",
        "yaxis_lightning_strike_count": "Anzahl der Blitzeinschläge",
        "yaxis_lower_loop": "Untere Schlaufe",
        "yaxis_mass": "Masse (kg)",
        "yaxis_maximum_wind_speed": "Maximale Windgeschwindigkeit (m/s)",
        "yaxis_odometer": "Kilometer (km)",
        "yaxis_potential": "Potential",
        "yaxis_power": "Total (W)",
        "yaxis_precipitation": "Niederschlag (mm/h)",
        "yaxis_precipitation_absolute": "Niederschlag absolut (mm)",
        "yaxis_precipitation_intensity": "Niederschlagsintensität (mm/h)",
        "yaxis_roadMaintenance_humidity": "Luftfeuchtigkeit (%)",
        "yaxis_roadMaintenance_temperature_0": "Lufttemperatur (°C)",
        "yaxis_roadMaintenance_temperature_1": "Oberflächentemperatur (°C)",
        "yaxis_roadMaintenance_temperature_2": "Temperatur des Sensorkopfes (°C)",
        "yaxis_roadMaintenance_temperature_3": "Taupunkt (°C)",
        "yaxis_running_time": "Betriebsstunden",
        "yaxis_signal": "RSSI (dBm)",
        "yaxis_silo_mass": "Masse (t)",
        "yaxis_solar_radiation": "Sonneneinstrahlung (W/m^2)",
        "yaxis_speed": "Geschwindigkeit (km/h)",
        "yaxis_tachograph_odometer": "Kilometer (km)",
        "yaxis_tau": "Tau",
        "yaxis_temperature": "Temperatur (°C)",
        "yaxis_temperature_0": "Batterietemperatur (°C)",
        "yaxis_temperature_1": "AFE Temperatur (°C)",
        "yaxis_upper_loop": "Obere Schlaufe",
        "yaxis_vapor_pressure": "Wasserdampfdruck (kPa)",
        "yaxis_voc": "Flüchtige organische Verbindungen (ppb)",
        "yaxis_voltage_0": "Batteriespannung (V)",
        "yaxis_volume": "Volumen (m³)",
        "yaxis_water_level": "Wasserpegel",
        "yaxis_wind_direction": "Windrichtung (°)",
        "yaxis_wind_speed": "Windgeschwindigkeit (m/s)"
    },
    "fr": {
        "moving": "Déménagement",
        "yaxis_barometric_pressure": "Pression barométrique (hPa)",
        "yaxis_battery": "Batterie (%)",
        "yaxis_battery_percentage": "Etat de la charge (%)",
        "yaxis_battery_voltage": "Tension de la batterie (V)",
        "yaxis_brightness": "Luminosité",
        "yaxis_co2": "Dioxyde de carbone (ppm)",
        "yaxis_d1_count": "Power up",
        "yaxis_distance": "Distance (m)",
        "yaxis_dragino_voltage_0": "Tension 0 (V)",
        "yaxis_dragino_voltage_1": "Tension 1 (V)",
        "yaxis_energy_0": "Energie (kWh)",
        "yaxis_ev_battery_health": "EV état de la batterie (%)",
        "yaxis_ev_battery_percentage": "EV pourcentage de la batterie (%)",
        "yaxis_ev_charging_state": "EV état de charge",
        "yaxis_external_voltage": "Voltaggio externe (V)",
        "yaxis_fency-boy_d1_count": "Impulsions",
        "yaxis_fency-boy_voltage_0": "Tension de la clôture (kV)",
        "yaxis_fillLevel": "Niveau de remplissage (%)",
        "yaxis_fill_level": "Niveau de remplissage (%)",
        "yaxis_fuel_level": "Niveau de carburant (%)",
        "yaxis_fuel_rate": "Taux de carburant (l/h)",
        "yaxis_humidity": "Humidité",
        "yaxis_humidity_absolute": "Humidité absolue (g/m³)",
        "yaxis_level": "Level (m)",
        "yaxis_lightning_average_distance": "Distance moyenne des éclairs (km)",
        "yaxis_lightning_strike_count": "Nombre de coups de foudre",
        "yaxis_lower_loop": "Boucle inférieure",
        "yaxis_mass": "Masse (kg)",
        "yaxis_maximum_wind_speed": "Vitesse maximale du vent (m/s)",
        "yaxis_odometer": "Kilomètre (km)",
        "yaxis_potential": "Potentiel",
        "yaxis_power": "Total (W)",
        "yaxis_precipitation": "Précipitations (mm/h)",
        "yaxis_precipitation_absolute": "Précipitations absolues (mm)",
        "yaxis_precipitation_intensity": "Intensité des précipitations (mm/h)",
        "yaxis_roadMaintenance_humidity": "Humidité de l'air (%)",
        "yaxis_roadMaintenance_temperature_0": "Température de l'air (°C)",
        "yaxis_roadMaintenance_temperature_1": "Température de surface (°C)",
        "yaxis_roadMaintenance_temperature_2": "Température de la tête du capteur (°C)",
        "yaxis_roadMaintenance_temperature_3": "Point de rosée (°C)",
        "yaxis_running_time": "Temps de fonctionnement",
        "yaxis_signal": "RSSI (dBm)",
        "yaxis_silo_mass": "Masse (t)",
        "yaxis_solar_radiation": "Rayonnement solaire (W/m^2)",
        "yaxis_speed": "Vitesse (km/h)",
        "yaxis_tachograph_odometer": "Kilomètre (km)",
        "yaxis_tau": "Tau",
        "yaxis_temperature": "Température (°C)",
        "yaxis_temperature_0": "Température de la batterie (°C)",
        "yaxis_temperature_1": "Température AFE (°C)",
        "yaxis_upper_loop": "Boucle supérieure",
        "yaxis_vapor_pressure": "Pression de vapeur (kPa)",
        "yaxis_voc": "Composants organiques volatiles (ppb)",
        "yaxis_voltage_0": "Tension de la batterie (V)",
        "yaxis_volume": "Volume (m³)",
        "yaxis_water_level": "Niveau de l'eau",
        "yaxis_wind_direction": "Direction du vent (°)",
        "yaxis_wind_speed": "Vitesse du vent (m/s)"
    },
    "it": {
        "moving": "Spostamento",
        "yaxis_barometric_pressure": "Pressione barometrica (hPa)",
        "yaxis_battery": "Batteria (%)",
        "yaxis_battery_percentage": "State of Charge (%)",
        "yaxis_battery_voltage": "Tensione della batteria (V)",
        "yaxis_brightness": "Luminosità",
        "yaxis_co2": "Anidride carbonica (ppm)",
        "yaxis_d1_count": "Power up",
        "yaxis_distance": "Distanza (m)",
        "yaxis_dragino_voltage_0": "Tensione 0 (V)",
        "yaxis_dragino_voltage_1": "Tensione 1 (V)",
        "yaxis_energy_0": "Energia (kWh)",
        "yaxis_ev_battery_health": "EV stato della batteria (%)",
        "yaxis_ev_battery_percentage": "EV percentuale della batteria (%)",
        "yaxis_ev_charging_state": "EV stato di carica",
        "yaxis_external_voltage": "Tensione esterna (V)",
        "yaxis_fency-boy_d1_count": "Impulsi",
        "yaxis_fency-boy_voltage_0": "Voltaggio Recinzione (kV)",
        "yaxis_fillLevel": "Riempimento (%)",
        "yaxis_fill_level": "Riempimento (%)",
        "yaxis_fuel_level": "Livello del carburante (%)",
        "yaxis_fuel_rate": "Tasso di carburante (l/h)",
        "yaxis_humidity": "Umidità (%)",
        "yaxis_humidity_absolute": "Umidità assoluta (g/m³)",
        "yaxis_level": "Livello (m)",
        "yaxis_lightning_average_distance": "Distanza media dei fulmini (km)",
        "yaxis_lightning_strike_count": "Conteggio dei fulmini",
        "yaxis_lower_loop": "Loop inferiore",
        "yaxis_mass": "Massa (kg)",
        "yaxis_maximum_wind_speed": "Velocità massima del vento (m/s)",
        "yaxis_odometer": "Chilometro (km)",
        "yaxis_potential": "Potential",
        "yaxis_power": "Totalmente (W)",
        "yaxis_precipitation": "Precipitazioni (mm/h)",
        "yaxis_precipitation_absolute": "Precipitazioni assolute (mm)",
        "yaxis_precipitation_intensity": "Intensità delle precipitazioni (mm/h)",
        "yaxis_roadMaintenance_humidity": "Umidità dell'aria (%)",
        "yaxis_roadMaintenance_temperature_0": "Temperatura dell'aria (°C)",
        "yaxis_roadMaintenance_temperature_1": "Temperatura di superficie (°C)",
        "yaxis_roadMaintenance_temperature_2": "Temperatura della testa del sensore (°C)",
        "yaxis_roadMaintenance_temperature_3": "Punto di rugiada (°C)",
        "yaxis_running_time": "Tempo di Esecuzione",
        "yaxis_signal": "RSSI (dBm)",
        "yaxis_silo_mass": "Massa (t)",
        "yaxis_solar_radiation": "Radiazione solare (W/m^2)",
        "yaxis_speed": "Velocità (km/h)",
        "yaxis_tachograph_odometer": "Chilometro (km)",
        "yaxis_tau": "Tau",
        "yaxis_temperature": "Temperatura (°C)",
        "yaxis_temperature_0": "Battery Temperatura (°C)",
        "yaxis_temperature_1": "AFE Temperatura (°C)",
        "yaxis_upper_loop": "Loop superiore",
        "yaxis_vapor_pressure": "Pressione del vapore (kPa)",
        "yaxis_voc": "Composti organici volatili (ppb)",
        "yaxis_voltage_0": "Livello dell batteria (V)",
        "yaxis_volume": "Volume (m³)",
        "yaxis_water_level": "Livello dell'acqua",
        "yaxis_wind_direction": "Direzione del vento (°)",
        "yaxis_wind_speed": "Vitesse du vent (m/s)"
    }
}
</i18n>

<style lang="scss" scoped>
.chart-view {
    display: flex;

    &__chart {
        flex-grow: 100;
        padding: 1rem;
        border-left: $style-border;
        overflow-x: hidden;
    }

    &__datepicker {
        width: 20%;
        min-width: 350px;
    }

    @include respond-to('for-tablet-down') {
        display: block;

        &__chart {
            border: none;
        }

        &__datepicker {
            padding: 1rem;
            width: auto;
            min-width: auto;
            border-bottom: $style-border;
        }
    }
}
</style>
